import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios';
import { clerkPlugin } from 'vue-clerk'
import router from './router'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Ripple from 'primevue/ripple';
import Lara from '@primevue/themes/lara';
import { definePreset } from '@primevue/themes';
import './index.css';
import 'primeicons/primeicons.css';

// Import PrimeVue components
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmationService from 'primevue/confirmationservice';
import Row from 'primevue/row';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import TabMenu from 'primevue/tabmenu';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Tag from 'primevue/tag';
import Sidebar from 'primevue/sidebar';
import Chips from 'primevue/chips';

const app = createApp(App)

app.use(clerkPlugin, {
  publishableKey: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY
})

const MyPreset = definePreset(Lara, {
    semantic: {
        primary: {
            50: '{green.50}',
            100: '{green.100}',
            200: '{green.200}',
            300: '{green.300}',
            400: '{green.400}',
            500: '{green.500}',
            600: '{green.600}',
            700: '{green.700}',
            800: '{green.800}',
            900: '{green.900}',
            950: '{green.950}'
        },
        secondary: {
            50: '{yellow.50}',
            100: '{yellow.100}',
            200: '{yellow.200}',
            300: '{yellow.300}',
            400: '{yellow.400}',
            500: '{yellow.500}',
            600: '{yellow.600}',
            700: '{yellow.700}',
            800: '{yellow.800}',
            900: '{yellow.900}',
            950: '{yellow.950}'
        },
        colorScheme: {
            light: {
                primary: {
                    background: '#F5F5F5',  // Light gray for background to improve readability
                    color: '#D4A537',        // Darkened yellow for text
                    contrastColor: '#FFFFFF', // White text for contrast
                    hoverColor: '#D4A537',    // Darkened yellow for hover state
                    activeColor: '#B7950B'    // Even darker yellow for active state
                },
                secondary: {
                    background: '#F9F7F2',  // Off-White for a clean look
                    color: '#357266',        // Deep Teal for secondary text
                    contrastColor: '#FFFFFF', // White text for contrast
                    hoverColor: '#285D52',    // Darkened teal for hover state
                    activeColor: '#1C3F3A'    // Darker teal for active state
                },
                success: {
                    background: '#E1F3E4',  // Light green background for success
                    color: '#2E7D32',        // Forest Green for success text
                    contrastColor: '#FFFFFF', // White text for contrast
                    hoverColor: '#2B6C2D',    // Darker green for hover state
                    activeColor: '#20502A'    // Even darker green for active state
                },
                info: {
                    background: '#D4ECEE',  // Light teal background for info
                    color: '#357266',        // Deep Teal for info text
                    contrastColor: '#FFFFFF', // White text for contrast
                    hoverColor: '#285D52',    // Darker teal for hover state
                    activeColor: '#1C3F3A'    // Even darker teal for active state
                },
                warn: {
                    background: '#FFE7D2',  // Light orange background for warnings
                    color: '#E67E22',        // Orange for warning text
                    contrastColor: '#FFFFFF', // White text for contrast
                    hoverColor: '#D35400',    // Darker orange for hover state
                    activeColor: '#A84300'    // Even darker orange for active state
                },
                danger: {
                    background: '#FADBD8',  // Light red background for danger
                    color: '#C0392B',        // Brick Red for danger text
                    contrastColor: '#FFFFFF', // White text for contrast
                    hoverColor: '#A93226',    // Darker red for hover state
                    activeColor: '#7B241C'    // Even darker red for active state
                },
                contrast: {
                    background: '#757575',  // Warm Grey for contrast background
                    color: '#FFFFFF',        // White for contrast text
                    contrastColor: '#FFFFFF', // White text for contrast
                    hoverColor: '#5E5E5E',    // Lighter grey for hover state
                    activeColor: '#4A4A4A'    // Even lighter grey for active state
                }
            }
        }
    }
});

app.use(PrimeVue, {
    ripple: true,
    theme: {
        preset: Lara,
        options: {
            prefix: 'p',
            darkModeSelector: '.no-dark-mode'
        }
    }
});

app.use(ToastService);
app.use(ConfirmationService);
app.directive('ripple', Ripple);

// Register PrimeVue components
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('Row', Row);
app.component('InputText', InputText);
app.component('Button', Button);
app.component('Toolbar', Toolbar);
app.component('TabMenu', TabMenu);
app.component('Dropdown', Dropdown);
app.component('MultiSelect', MultiSelect);
app.component('Tag', Tag);
app.component('Sidebar', Sidebar);
app.component('Chips', Chips);

app.use(router)

axios.interceptors.request.use((config) => {
    const threadId = localStorage.getItem('threadId');
    if (threadId) {
        config.headers['X-Thread-ID'] = threadId;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

app.mount('#app')
